.pf-carousel {
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 2px;
  padding: 0 4px;
  max-height: 550px;
  transition: transform 0.24s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative; }
  .pf-carousel div, .pf-carousel img, .pf-carousel span, .pf-carousel a {
    box-sizing: border-box; }
  @media all and (max-width: 700px) {
    .pf-carousel {
      max-height: 320px; } }
  .pf-carousel .pf-slider {
    overflow-y: scroll;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    display: flex;
    position: relative;
    height: 100%; }
  .pf-carousel .pf-item {
    border-radius: 3px;
    margin-right: 4px;
    display: none;
    position: relative;
    height: 100%;
    overflow: hidden; }
  .pf-carousel:not(.is-ready) .pf-item:not(.is-loaded),
  .pf-carousel .pf-placeholder-item {
    display: block;
    height: 100%;
    width: 360px;
    flex-shrink: 0;
    border-radius: 2px 0 0 2px;
    background: rgba(0, 0, 0, 0.12);
    animation: blink 2s ease-in-out infinite; }
  .pf-carousel.is-ready .pf-item.is-loaded {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto; }
  @media all and (max-width: 700px) {
    .pf-carousel .pf-placeholder-item {
      width: 180px; } }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.2; }
  80% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }
  .pf-carousel .pf-item-image {
    height: 100%;
    width: auto;
    display: none; }
  .pf-carousel.is-ready .pf-item-image {
    display: inline-block; }
  .pf-carousel .pf-item-description {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    background: rgba(0, 0, 0, 0.24);
    min-height: 20px;
    padding: 12px;
    box-sizing: border-box;
    display: none; }
  .pf-carousel.is-ready .pf-item-description {
    display: block; }
  .pf-carousel .pf-navigation-button-left,
  .pf-carousel .pf-navigation-button-right {
    background: url("./images/ic_keyboard_arrow_right_white_48px.svg") transparent no-repeat;
    height: 48px;
    width: 48px;
    flex-shrink: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background-size: 72px;
    background-position: center center;
    outline: 0;
    display: none; }
  @media all and (max-width: 700px) {
    .pf-carousel .pf-navigation-button-left,
    .pf-carousel .pf-navigation-button-right {
      background-size: auto; } }
  .pf-carousel .pf-navigation-button-left {
    left: 12px;
    transform: scaleX(-1) translateY(-50%); }
  .pf-carousel .pf-navigation-button-right {
    right: 12px; }
  .pf-carousel:hover .pf-navigation-button-left,
  .pf-carousel:hover .pf-navigation-button-right {
    display: block; }
  .pf-carousel.at-left-edge .pf-navigation-button-left {
    display: none; }
  .pf-carousel.at-right-edge .pf-navigation-button-right {
    display: none; }
